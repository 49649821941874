<template>
    <div>
        <!-- 選擇合作夥伴視窗 -->
        <selectPartner :openPopup="activeSelectPartner" @closePartnerPopup="closePartner"></selectPartner>
        <!-- 掛號視窗 -->
        <appt :activePopup="activeAppt" :patientData="patient" :mode="apptMode" @closeApptPopup="closeAppt"></appt>
        <!-- 聊天室視窗 -->
        <chat :chatPopup="activeChat" :smid="parseInt(smid)" :sname="sname" @closeChat="closeChat"></chat>
        <!-- 發送簡訊邀請民眾視窗 -->
        <invite-popup :invitePopupActive="activeInvite" :qid="parseInt(invite.qid)" :pid="invite.pid" :pmid="invite.mid" @closePopup="closeInvite"></invite-popup>
    </div>
</template>
<script>
import selectPartner from '@/components/selectPartner.vue'
import appt from '@/components/apptBtn.vue'
import chat from '@/components/sendChat'
import InvitePopup from '@/components/invitePopup'

export default {
    components: { selectPartner, appt, chat, InvitePopup },
    data() {
        return {
            activeSelectPartner: false,
            activeAppt: false,
            activeChat: false,
            activeInvite: false,
            apptMode: 0,
            patient: {
                pid: null,
                name: null,
            },
            smid: 0,
            sname: '',
            invite: {
                qid: 0,
                pid: 0,
                mid: 0,
            },
        }
    },
    created() {
        this.createBus()
    },
    methods: {
        createBus() {
            this.$bus.$on('selectPartner', () => {
                this.openPartner()
            })
            this.$bus.$on('appt', (item) => {
                this.apptMode = item.apptMode
                this.patient = item.patient
                this.openAppt()
            })
            this.$bus.$on('chat', (item) => {
                this.smid = item.smid
                this.sname = item.sname
                this.openChat()
            })
            this.$bus.$on('invite', (item) => {
                this.invite = item
                this.openInvite()
            })
        },
        openPartner() {
            this.activeSelectPartner = true
        },
        closePartner() {
            this.activeSelectPartner = false
        },
        openAppt() {
            this.activeAppt = true
        },
        closeAppt() {
            this.activeAppt = false
        },
        openChat() {
            this.activeChat = true
        },
        closeChat() {
            this.activeChat = false
        },
        openInvite() {
            this.activeInvite = true
        },
        closeInvite() {
            this.activeInvite = false
        },
    },
    beforeDestroy() {
        this.$bus.$off('selectPartner')
        this.$bus.$off('appt')
        this.$bus.$off('chat')
        this.$bus.$off('invite')
    },
}
</script>
