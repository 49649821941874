// 這是目前用的

import _ from 'lodash'
var all_permission =
[
  {url: "/",                name: "儀錶板",     slug: "home",         icon: "HomeIcon",           i18n: "Dashboard",},
  {url: "/reservation",     name: "掛號管理",   slug: "reservation",  icon: "CalendarIcon",       i18n: "Reservation"},
  {url: "/advisory",        name: "諮詢紀錄",   slug: "advisory",     icon: "PhoneCallIcon",      i18n: "Advisory"},
  {url: "/todolist",        name: "待辦事項",   slug: "todolist",     icon: "ListIcon",           i18n: "Todolist"},
  {url: "/message/chat",    name: "聊天室",     slug: "chat",         icon: "MessageCircleIcon",  i18n: "Chat"},
  {url: "/message/notify",  name: "通知",       slug: "notify",       icon: "BellIcon",           i18n: "Notify"},
  {url: "/setting",         name: "系統設定",   slug: "setting",      icon: "SettingsIcon",       i18n: "Setting",
    submenu: [
      {url: "/setting/teach_install",  name: "使用教學",   slug: "User_Guide",     icon: "AlignLeftIcon",      i18n: "User_Guide"},
      {url: "/setting/shift",          name: "班表設定",   slug: "shift",          icon: "AlignLeftIcon",      i18n: "Shift"},
      {url: "/authorization",          name: "授權管理",   slug: "authorization",  icon: "AlignLeftIcon",      i18n: "Authorization"},
      {url: "/setting/contact",        name: "聯絡人",     slug: "contact",        icon: "Share2Icon",         i18n: "Contact"},
      {url: "/setting/setword",        name: "常用句設定", slug: "setword",        icon: "Share2Icon",         i18n: "SetWord"},
      {url: "/setting/profile",        name: "個人設定",   slug: "Profile",        icon: "MessageSquareIcon",  i18n: "Profile"},
      {url: "/setting/log",            name: "操作紀錄",   slug: "log",            icon: "MessageSquareIcon",  i18n: "Log"},
      {url: "/setting/privacy",        name: "註冊合約",   slug: "privacy",        icon: "MessageSquareIcon",  i18n: "Privacy"},
    ]
  },
  {url: "/members",           name: "會員管理",       slug: "members",      icon: "UsersIcon",          i18n: "Members"},
  {url: "/monitor",           name: "遠距監看",       slug: "Monitor",      icon: "MonitorIcon",        i18n: "Monitor"},
  {url: "/opinion",           name: "會診管理",       slug: "opinion",      icon: "Share2Icon",         i18n: "Opinion"},
  {url: "/crm",               name: "CRM系統",        slug: "crm",          icon: "HeadphonesIcon",     i18n: "CRM"},
  {url: "/message/schedule",  name: "我的預約",       slug: "schedule",     icon: "ClipboardIcon",      i18n: "Schedule"},
  {url: "/upload_select",    name: "醫療檔案上傳",   slug: "upload",       icon: "UploadIcon",         i18n: "Upload"},
]
export default all_permission