<template>
  <div>
    <h4>{{$t('privacy.mainTitle')}}</h4>
    <vs-collapse accordion >
      <!-- 警語 -->
      <vs-collapse-item class="cd-collapse-copyright-warning">
        <div slot="header">
          {{$t('privacy.title1')}}
        </div>
        {{$t('privacy.desc1')}}
      </vs-collapse-item>
      <!-- 平台免責權 -->
      <vs-collapse-item class="cd-collapse-copyright-warning">
        <div slot="header">
          {{$t('privacy.title2')}}
        </div>
        {{$t('privacy.desc2')}}
      </vs-collapse-item>
      <!-- 諮詢過程錄音錄影 -->
      <vs-collapse-item class="cd-collapse-copyright-warning">
        <div slot="header">
          {{$t('privacy.title3')}}
        </div>
        {{$t('privacy.desc3')}}
      </vs-collapse-item>
      <!-- 諮詢費及平台服務費 (判斷CLC不顯示諮詢費及平台服務費說明) -->
      <vs-collapse-item v-if="partnerid!=1055&&partnerid!=1574">
        <div slot="header">
          {{$t('privacy.title4')}}
        </div>
        {{$t('privacy.desc4')}}
      </vs-collapse-item>
      <!-- 客戶個資之使用 -->
      <vs-collapse-item>
        <div slot="header">
          {{$t('privacy.title5')}}
        </div>
        {{$t('privacy.desc5')}}
      </vs-collapse-item>
      <!-- 取消醫護人員會員權利 -->
      <vs-collapse-item>
        <div slot="header">
          {{$t('privacy.title6')}}
        </div>
        {{$t('privacy.desc6')}}
      </vs-collapse-item>
      <!-- 個資安全及隱私權聲明 -->
      <vs-collapse-item>
        <div slot="header">
          {{$t('privacy.title7')}}
        </div>
        <div>
          {{$t('privacy.desc7_1')}}
        </div>
        <vs-divider></vs-divider>
        <div>
          {{$t('privacy.desc7_2')}}
        </div>
        <br/>
        <div>
          {{$t('privacy.desc7_3')}}
        </div>
        <br/>
        <div>
          {{$t('privacy.desc7_4')}}
        </div>
        <br/>
        <div>
          {{$t('privacy.desc7_5')}}
        </div>
        <br/>
        <div>
          {{$t('privacy.desc7_6')}}
        </div>
        <br/>
        <div>
          {{$t('privacy.desc7_7')}}
        </div>
        <br/>
        <div>
          {{$t('privacy.desc7_8')}}
        </div>
        <br/>
        <div>
          {{$t('privacy.desc7_9')}}
        </div>
        <br/>
        <div>
          {{$t('privacy.desc7_10')}}
        </div>
      </vs-collapse-item>
    </vs-collapse>
  </div>
</template>
<script>
export default {
  computed: {
    partnerid() {
      return this.$store.getters.partner
    },
  },
}
</script>