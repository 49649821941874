<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, { 'app-page': isAppPage }]">
        <the-customizer
            v-if="!disableCustomizer"
            :footerType="footerType"
            :hideScrollToTop="hideScrollToTop"
            :navbarType="navbarType"
            :navbarColor="navbarColor"
            :routerTransition="routerTransition"
            @toggleHideScrollToTop="toggleHideScrollToTop"
            @updateFooter="updateFooter"
            @updateNavbar="updateNavbar"
            @updateNavbarColor="updateNavbarColor"
            @updateRouterTransition="updateRouterTransition"
        />
        <!-- <stream></stream> -->
        <!-- :logo           = "navMenuLogo"
      :curlogo        = "navMenuLogoCur"
      :alliancelogo   = "navMenuLogoAlliance"
      :chenghsinlogo  = "navMenuLogoChengHsin"
      :CLClogo        = "navMenuLogoCLCcare"
      :camiansaintlogo = "navMenuLogoCamianSaint"
      :pingchristlogo = "navMenuLogPingChrist"
      :huayulogo      = "navMenuLogHuayu" -->
        <v-nav-menu :navMenuItems="navMenuNormal" title="基隆長庚社區" title2="醫學科研中心" allianceTitle="遠距之愛" curtitle="醫生馬上看" chengHsinTitle="振興醫院" PingChrisTitle="屏東基督教醫院" CLCTitle="CLC care" parent=".layout--main" />
        <div id="content-area" :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]">
            <div id="content-overlay" />
            <!-- Navbar -->
            <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
                <the-navbar-horizontal :navbarType="navbarType" :logo="navMenuLogo" :class="[{ 'text-white': isNavbarDark && !isThemeDark }, { 'text-base': !isNavbarDark && isThemeDark }]" />

                <div style="height: 62px" v-if="navbarType === 'static'"></div>

                <h-nav-menu :class="[{ 'text-white': isNavbarDark && !isThemeDark }, { 'text-base': !isNavbarDark && isThemeDark }]" :navMenuItems="navMenuNormal" />
            </template>
            <template v-else>
                <the-navbar-vertical :navbarColor="navbarColor" :class="[{ 'text-white': isNavbarDark && !isThemeDark }, { 'text-base': !isNavbarDark && isThemeDark }]" />
            </template>
            <!-- /Navbar -->
            <div class="content-wrapper">
                <div class="router-view">
                    <div class="router-content">
                        <transition :name="routerTransition">
                            <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                                <div class="content-area__heading" :class="{ 'pr-4 border-0 md:border-r border-solid border-grey-light': $route.meta.breadcrumb }">
                                    <h2 class="mb-1">{{ routeTitle }}</h2>
                                </div>
                                <!-- BREADCRUMB -->
                                <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" />
                                <!-- DROPDOWN -->
                                <vs-dropdown vs-trigger-click class="ml-auto md:block hidden cursor-pointer">
                                    <vs-button radius icon="icon-settings" icon-pack="feather" />

                                    <vs-dropdown-menu class="w-32">
                                        <vs-dropdown-item>
                                            <div @click="$router.push('/pages/profile').catch(() => {})" class="flex items-center">
                                                <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                                                <span>Profile</span>
                                            </div>
                                        </vs-dropdown-item>
                                        <vs-dropdown-item>
                                            <div @click="$router.push('/apps/todo').catch(() => {})" class="flex items-center">
                                                <feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                                                <span>Tasks</span>
                                            </div>
                                        </vs-dropdown-item>
                                        <vs-dropdown-item>
                                            <div @click="$router.push('/apps/email').catch(() => {})" class="flex items-center">
                                                <feather-icon icon="MailIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                                                <span>Inbox</span>
                                            </div>
                                        </vs-dropdown-item>
                                    </vs-dropdown-menu>
                                </vs-dropdown>
                            </div>
                        </transition>
                        <div class="content-area__content">
                            <back-to-top bottom="5%" visibleoffset="500" v-if="!hideScrollToTop">
                                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
                            </back-to-top>
                            <transition :name="routerTransition" mode="out-in">
                                <router-view @changeRouteTitle="changeRouteTitle"></router-view>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
            <the-footer />
        </div>
        <dialogs />
    </div>
</template>

<script>
import _ from 'lodash'
// import axios from 'axios'
import BackToTop from 'vue-backtotop'
import HNavMenu from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue'
import navMenuNormal from '@/layouts/components/vertical-nav-menu/navMenuNormal.js'
// import navMenuItems        from "@/layouts/components/vertical-nav-menu/navMenuItems.js"
// import navMenuItems2       from "@/layouts/components/vertical-nav-menu/navMenuItems2.js"
// import navUIAMenuItems     from "@/layouts/components/vertical-nav-menu/navUIAMenuItems.js"
// import navCustomerMenuItems     from "@/layouts/components/vertical-nav-menu/navCustomerMenuItems.js"
import TheCustomizer from '@/layouts/components/customizer/TheCustomizer.vue'
// import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue'
import TheNavbarVertical from '@/layouts/components/navbar/TheNavbarVertical.vue'
import TheFooter from '@/layouts/components/TheFooter.vue'
import themeConfig from '@/../themeConfig.js'
import VNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import Copyright from '../../views/apps/emrcomponents/Copyright.vue'
// import stream               from "@/layouts/components/stream/stream.vue"
import { mounted } from '@chenfengyuan/vue-countdown'
import dialogs from '@/components/Module/dialogs.vue'

export default {
    components: {
        BackToTop,
        HNavMenu,
        TheCustomizer,
        TheFooter,
        // TheNavbarHorizontal,
        TheNavbarVertical,
        VNavMenu,
        Copyright,
        // stream
        dialogs,
    },
    data() {
        return {
            disableCustomizer: false, //themeConfig.disableCustomizer,
            disableThemeTour: themeConfig.disableThemeTour,
            footerType: themeConfig.footerType || 'static',
            hideScrollToTop: themeConfig.hideScrollToTop,
            isNavbarDark: false,
            navbarColor: themeConfig.navbarColor || '#fff',
            navbarType: themeConfig.navbarType || 'floating',
            // navMenuItems      : navMenuItems,
            // navMenuLogo           : require('@/assets/images/logo/logo2.png'),
            // navMenuLogoCur        : require('@/assets/images/logo/logo.png'),
            // navMenuLogoAlliance   : require('@/assets/images/logo/alliance.png'),
            // navMenuLogoChengHsin  : require('@/assets/images/logo/chenghsin.png'),
            // navMenuLogoCamianSaint  : require('@/assets/images/logo/camiansaintlogo.png'),
            // navMenuLogoCLCcare    : require('@/assets/images/logo/clc_care.png'),
            // navMenuLogPingChrist  : require('@/assets/images/logo/pingchrist.png'),
            // navMenuLogHuayu       : require('@/assets/images/logo/huayu_logo.png'),
            routerTransition: themeConfig.routerTransition || 'none',
            routeTitle: this.$route.meta.pageTitle,
        }
    },
    watch: {
        $route() {
            this.routeTitle = this.$route.meta.pageTitle
        },
        isThemeDark(val) {
            const color = this.navbarColor == '#fff' && val ? '#10163a' : '#fff'
            this.updateNavbarColor(color)
        },
        '$store.state.mainLayoutType'(val) {
            this.setNavMenuVisibility(val)
            this.disableThemeTour = true
        },
        windowWidth(val) {
            if (val < 1200) this.disableThemeTour = true
        },
        verticalNavMenuWidth() {
            this.disableThemeTour = true
        },
    },
    computed: {
        partnerid() {
            return this.$store.getters.partner
        },
        doctortype() {
            return this.$store.state.doctortype
        },
        bodyOverlay() {
            return this.$store.state.bodyOverlay
        },
        contentAreaClass() {
            let result = 'content-area-full'
            if (this.mainLayoutType === 'vertical') {
                if (this.verticalNavMenuWidth == 'default') {
                    result = 'content-area-reduced'
                } else if (this.verticalNavMenuWidth == 'reduced') {
                    result = 'content-area-lg'
                }
            } else {
                result = 'content-area-full'
            }
            return result
        },
        footerClasses() {
            return {
                'footer-hidden': this.footerType == 'hidden',
                'footer-sticky': this.footerType == 'sticky',
                'footer-static': this.footerType == 'static',
            }
        },
        //判斷特使用者使用什麼權限
        navMenuNormal() {
            return navMenuNormal
        },
        isAppPage() {
            return this.$route.path.includes('/apps/') ? true : false
        },
        isThemeDark() {
            return this.$store.state.theme == 'dark'
        },
        layoutTypeClass() {
            return `main-${this.mainLayoutType}`
        },
        mainLayoutType() {
            return this.$store.state.mainLayoutType
        },
        navbarClasses() {
            return {
                'navbar-hidden': this.navbarType == 'hidden',
                'navbar-sticky': this.navbarType == 'sticky',
                'navbar-static': this.navbarType == 'static',
                'navbar-floating': this.navbarType == 'floating',
            }
        },
        verticalNavMenuWidth() {
            return this.$store.state.verticalNavMenuWidth
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
    },
    methods: {
        changeRouteTitle(title) {
            this.routeTitle = title
        },
        updateNavbar(val) {
            if (val == 'static') this.updateNavbarColor('#fff')
            this.navbarType = val
        },
        updateHello(val) {
            alert(val)
        },
        updateNavbarColor(val) {
            this.navbarColor = val
            if (val == '#fff') this.isNavbarDark = false
            else this.isNavbarDark = true
        },
        updateFooter(val) {
            this.footerType = val
        },
        updateRouterTransition(val) {
            this.routerTransition = val
        },
        setNavMenuVisibility(layoutType) {
            if ((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === 'vertical' && this.windowWidth < 1200)) {
                this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
                this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
            } else {
                this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
            }
        },
        toggleHideScrollToTop(val) {
            this.hideScrollToTop = val
        },
        // handleBeforeUnload(event) {
        //   var _self = this
        //   _self.$store.dispatch('updateDoctorStatus', 3000)
        //   event.preventDefault()
        // },
    },
    created() {
        // window.addEventListener('beforeunload', this.handleBeforeUnload)
        const color = this.navbarColor == '#fff' && this.isThemeDark ? '#10163a' : this.navbarColor
        this.updateNavbarColor(color)
        this.setNavMenuVisibility(this.$store.state.mainLayoutType)
    },
    beforeDestroy() {
        // window.location.reload()
        // window.removeEventListener('beforeunload', this.handleBeforeUnload)
    },
}
</script>
