<template>
    <!-- 提示視窗 - 您將發送會議連結，請病人進入診間。病人進入診間後，您也會收到提醒通知。 -->
    <vs-popup :title="$t('popup.alert')" :active.sync="popupActive" @close="close">
        <p class="cd-form-group">{{ $t('reservation.smsText') }}</p>
        <vs-button class="mr-2" type="border" @click="close">{{$t('popup.cancel')}}</vs-button>
        <vs-button class="ml-2" type="filled" @click="sendlink">{{$t('popup.confirm')}}</vs-button>
    </vs-popup>
</template>
<script>
import { inviteVideo } from '@/api/user'
export default {
    props: {
        invitePopupActive: {
            type: Boolean,
            required: true,
        },
        qid: {
            type: Number,
            required: false
        },
        pid: {
            type: Number,
            required: false
        },
        pmid: {
            type: Number,
            required: false
        },
    },
    computed: {
        popupActive: {
            get() {
                return this.invitePopupActive
            },
            set(v) {
                return v
            },
        },
    },
    methods: {
        // 發送簡訊連結
        sendlink(){
            this.$vs.loading()
            let payload = {
                "qid": this.qid,
                "type": 'video',
            }
            // 發送視訊邀請(含通知)
            inviteVideo(payload).then((res) => {
                this.$emit("closePopup")
                this.notify('success', this.$t('message.invite_success'), '')
                this.$store.dispatch('updateDoctorStatus', 2000)
                this.$vs.loading.close()
            }).catch((e) => {
                console.log('send sms error ', e)
                this.notify('danger', this.$t('message.invite_error'), '')
            })
            // 將該筆掛號加入定期檢查房間狀態的行列
            var mcudata = _.cloneDeep(this.$store.state.mcudata)
            if(!_.find(mcudata,{ 'mid': this.pmid, 'qid': this.qid})){
                mcudata.push({ 'mid': this.pmid, 'qid': this.qid })
            }
            this.$store.commit('SET_MCUDATA', mcudata)
        },
        // 視窗通知
        notify(color, title, text){
            this.$vs.notify({
                color: color,
                title: title,
                text: text,
                time: 8000,
                position:'top-center'
            })
        },
        // 關閉popup
        close(){
            this.$emit("closePopup")
        },
    }
}
</script>